<template>
    <p class="small mt-4 text-center">&copy; 2023 Arizona Board of Regents on behalf of the University of Arizona, Arizona Institute for Resilience</p>
</template>

<script>
export default {
  name: 'MainFooter'
}
</script>

<style scoped>

</style>
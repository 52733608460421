import { createRouter, createWebHistory } from 'vue-router';
import StationViewer from '../views/StationViewer.vue';
import HomeView from '../views/HomeView.vue';
import ModelAssessment from '../views/ModelAssessment.vue';
import AboutView from '../views/AboutView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home'}
  },
  {
    path: '/station/:wims_id',
    name: 'station',
    component: StationViewer,
    meta: { title: 'Details'}
  },
  {
    path: '/modelassessment/:wims_id',
    name: 'modelassessment',
    component: ModelAssessment,
    meta: { title: 'Model Assessment' }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { title: 'About' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
<template>
  <div id="container">
    <hr />
    <DataGraph :layout-trace="layoutTraces.Temperature" :traces="traces.Temperature" :refresh="refresh.Temperature" name="Temperature" />
    <hr />
    <DataGraph :layout-trace="layoutTraces.RelativeHumidity" :traces="traces.RelativeHumidity" :refresh="refresh.RelativeHumidity" name="RelativeHumidity" />
    <hr />
    <DataGraph :layout-trace="layoutTraces.WindSpeed" :traces="traces.WindSpeed" :refresh="refresh.WindSpeed" name="WindSpeed" />
  </div>
</template>

<script>
import DataGraph from '@/components/DataGraph.vue';

const { DateTime } = require('luxon');

export default {
  name: 'DataGraphs',
  props: {
    predictions: Array,
    actual: Array,
    build: Boolean
  },
  components:{
    DataGraph,
  },
  data(){
    return {
      temp_mae: null,
      humidity_mae: null,
      wind_mae: null,
      layoutTraces: {
        'Temperature': {},
        'RelativeHumidity': {},
        'WindSpeed': {}
      },
      traces: {
        'Temperature': [],
        'RelativeHumidity': [],
        'WindSpeed': []
      },
      refresh: {
        'Temperature': false,
        'RelativeHumidity': false,
        'WindSpeed': false
      },
      showAssessment: {
        'Temperature': false,
        'RelativeHumidity': false,
        'WindSpeed': false
      },
      hideButtons: false
    }
  },
  methods: {
    assessModels(){

    },
    generateGraphs(){
      this.layoutTraces.Temperature = {
        title: 'Temperature',
        xaxis: {
          title: 'Date/Time (America/Phoenix)',
          type: 'date'
        },
        yaxis: {
          title: 'Temperature (F)'
        },
      };

      this.layoutTraces.RelativeHumidity = {
        title: 'Relative Humidity',
        xaxis: {
          title: 'Date/Time (America/Phoenix)',
          type: 'date'
        },
        yaxis: {
          title: 'Relative Humidity (%)'
        },
        shapes: [{
          type: 'line',
          y0: 20,
          y1: 20,
          x0: 0,
          x1: 1,
          xref: 'paper',
          line: {
            color: 'gray',
            width: 1
          }
        }]
      };

      this.layoutTraces.WindSpeed = {
        title: 'Wind Speed',
        xaxis: {
          title: 'Date/Time (America/Phoenix)',
          type: 'date'
        },
        yaxis: {
          title: 'Wind Speed (mph)'
        },
        shapes: [{
          type: 'line',
          y0: 20,
          y1: 20,
          x0: 0,
          x1: 1,
          xref: 'paper',
          line: {
            color: 'gray',
            width: 1
          }
        }]
      };

      let pred_x = [];


      for(let i = 0; i < this.predictions.length; i++){
        pred_x[i] = [];
        for(let j = 0; j < this.predictions[i]['time'].length; j++){
          pred_x[i].push(this.convertDate(this.predictions[i]['time'][j], 'America/Phoenix'));
        }
      }

      let actual_x = Array();
      let actual_temp_y = Array();
      let actual_humidity_y = Array();
      let actual_wind_y = Array();

      for(let i = 0; i < this.actual.length; i++){
        actual_x.push(this.convertDate(this.actual[i]['time'], 'America/Phoenix'));
        actual_temp_y.push(this.actual[i]['Temperature'])
        actual_humidity_y.push(this.actual[i]['RelativeHumidity'])
        actual_wind_y.push(this.actual[i]['WindSpeed'])
      }

      for(let i = 0; i < this.predictions.length; i++){
        if(this.predictions[i] != undefined && this.actual[this.actual.length-1] != undefined && this.actual[this.actual.length-1]['time'] < this.predictions[i]['time'][0]){
          this.traces.Temperature.push({
            x: [this.convertDate(this.actual[this.actual.length-1]['time'], 'America/Phoenix'), this.convertDate(this.predictions[i]['time'][0], 'America/Phoenix')],
            y: [this.actual[this.actual.length-1]['Temperature'], this.predictions[i]['Temperature'][0]],
            type: 'scatter',
            name: 'Connector',
            showlegend: false,
            hoverinfo: 'none',
            line: {
              color: 'gray'
            },
            marker: {
              size: 1
            }
          })

          this.traces.RelativeHumidity.push({
            x: [this.convertDate(this.actual[this.actual.length-1]['time'], 'America/Phoenix'), this.convertDate(this.predictions[i]['time'][0], 'America/Phoenix')],
            y: [this.actual[this.actual.length-1]['RelativeHumidity'], this.predictions[i]['RelativeHumidity'][0]],
            type: 'scatter',
            name: 'Connector',
            showlegend: false,
            hoverinfo: 'none',
            line: {
              color: 'gray'
            },
            marker: {
              size: 1
            }
          })

          this.traces.WindSpeed.push({
            x: [this.convertDate(this.actual[this.actual.length-1]['time'], 'America/Phoenix'), this.convertDate(this.predictions[i]['time'][0], 'America/Phoenix')],
            y: [this.actual[this.actual.length-1]['WindSpeed'], this.predictions[i]['WindSpeed'][0]],
            type: 'scatter',
            name: 'Connector',
            showlegend: false,
            hoverinfo: 'none',
            line: {
              color: 'gray'
            },
            marker: {
              size: 1
            }
          })
        }
      }

      let colors = ['orange','blue','green']

      for(let i = 0; i < this.predictions.length; i++){
        this.traces.Temperature.push({
          x: pred_x[i],
          y: Object.values(this.predictions[i]['Temperature']),
          type: 'scatter',
          name: `Predicted (${this.predictions[i]['len']}HR)`,
          marker: {
            size: 1
          },
          line: {
            color: colors[i]
          }
        });
        this.traces.RelativeHumidity.push({
          x: pred_x[i],
          y: this.predictions[i]['RelativeHumidity'],
          type: 'scatter',
          name: `Predicted (${this.predictions[i]['len']}HR)`,
          marker: {
            size: 1
          },
          line: {
            color: colors[i]
          }
        });
        this.traces.WindSpeed.push({
          x: pred_x[i],
          y: this.predictions[i]['WindSpeed'],
          type: 'scatter',
          name: `Predicted (${this.predictions[i]['len']}HR)`,
          marker: {
            size: 1
          },
          line: {
            color: colors[i]
          }
        });
      }

      this.traces.Temperature.push({
        x: actual_x,
        y: actual_temp_y,
        type: 'scatter',
        name: 'Actual',
        line: {
          color: 'black'
        },
        marker: {
          size: 1
        }
      })

      this.traces.RelativeHumidity.push({
        x: actual_x,
        y: actual_humidity_y,
        type: 'scatter',
        name: 'Actual',
        line: {
          color: 'black'
        },
        marker: {
          size: 1
        }
      })

      this.traces.WindSpeed.push({
        x: actual_x,
        y: actual_wind_y,
        type: 'scatter',
        name: 'Actual',
        line: {
          color: 'black'
        },
        marker: {
          size: 1
        }
      })

      this.refresh.Temperature = true;
      this.refresh.RelativeHumidity = true;
      this.refresh.WindSpeed = true;

    },
    convertDate(date, tz){
      var dt = DateTime.fromJSDate(date.toDate()).setZone(tz)
      return dt.toFormat('y-LL-dd HH:mm:ss')
    },
    meanAverageError(actual, predicted, offset){
      let sum = 0;
      for(let i = 0; i < actual.length - offset; i++){
        sum += Math.abs(predicted[i] - actual[i + offset]);
      }
      return sum / (actual.length - offset);
    }
  },
  mounted() {
    //this.generateGraph();
  },
  watch: {
    build: function(newVal){
      if(newVal == true){
        this.generateGraphs();
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #container {
    max-width: 1000px;
    margin: auto;
  }
</style>

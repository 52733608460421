<template>
    <MainHeader />
    <div class="main-container">
        <h1 class="h3">About RAWS Machine Learning</h1>
        <p>The main idea behind this project is to leverage historical RAWS data to predict future data relating to fire-weather conditions.</p>
    </div>
    <MainFooter />
</template>
<script>
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
    name: 'AboutView',
    components: {
        MainHeader,
        MainFooter
    },
    mounted(){
        document.title = document.title +': About'
    }
}
</script>

<style scoped>
  .main-container {
    max-width: 1000px;
    margin: auto;
    box-sizing: border-box;
    padding: 1em;
  }
</style>
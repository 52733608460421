<template>
    <div class="popup-container">
        <div class="inner-container">
            <button type="button" class="btn-close" aria-label="Close" v-on:click="$emit('closeWindow')"></button>
            <DataGraph :refresh="refresh" :layout-trace="layoutTrace" :traces="traces" name="archive-plot"/>
        </div>
    </div>
</template>

<script>
import DataGraph from '@/components/DataGraph.vue';

export default {
    name: 'GraphPopup',
    props: {
        traces: Array,
        layoutTrace: Object,
    },
    components: {
        DataGraph
    },
    data(){
        return {
            refresh: false,
            count: 0
        }
    },
    methods: {

    },
    mounted(){

    },
    watch: {
        traces: function () {
            console.log('hello')
            this.refresh = false;
            this.refresh = true;
        }
    }
}
</script>

<style scoped>
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
    padding: 1em;
    background-color: rgb(0,0,0,0.6);
}
.inner-container {
    max-width: 1000px;
    box-sizing: border-box;
    margin: auto;
    padding: 1em;
    background-color: white;
    border-radius: 10px;
    max-height: 100%;
    overflow-y: auto;
}
</style>
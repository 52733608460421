<template>
  <MainHeader/>
  <div class="w-100 text-center" v-show="!doneLoading">
    <div class="spinner-border text-center" role="status">
      <span class="visually-hidden text-center">Loading...</span>
    </div>
  </div>
  <div class="main-container" v-if="validStation && hasPredictions">
    <div class="alert alert-danger" role="alert" v-if="actualLag">
      Missing latest data.
    </div>
    <div class="alert alert-danger" role="alert" v-if="predictionLag">
      Missing latest predictions.
    </div>
    <h1 class="mt-4 h3 text-center">{{ station_info.name }} ({{ wims_id }}) Station Details</h1>
    <p class="text-center"><a :href="'/modelassessment/' + wims_id" class="btn btn-primary">Model Assessment</a></p>
    <DataGraphs :predictions="predictions" :build="buildGraphs" :actual="actual" />
  </div>
  <div v-if="doneLoading">
    <div class="main-container" v-if="!validStation">
      <p class="text-center">The WIMS ID provided is invalid.</p>
    </div>
    <div class="main-container" v-if="validStation && !hasPredictions">
      <p class="text-center">This station has not been configured to generate predictions.</p>
    </div>
  </div>
  <MainFooter />
  </template>
  
  <script>
  import DataGraphs from '@/components/DataGraphs.vue';
  import MainFooter from '@/components/MainFooter.vue';
  import MainHeader from '@/components/MainHeader.vue';
  import { query, collection, orderBy, where, limit, getDocs, Timestamp } from "firebase/firestore";
  import db from '@/firebase/init.js';

  const now = Timestamp.now();
  
  export default {
    name: 'StationViewer',
    components: {
      DataGraphs,
      MainFooter,
      MainHeader
    },
    data() {
      return {
        predictions: [],
        actual: [],
        buildGraphs: false,
        predicted_start: null,
        station_info: {
          name: null,
          wims_id: null
        },
        validStation: false,
        hasPredictions: false,
        doneLoading: false,
        actualLag: false,
        predictionLag: false
      }
    }, 
    methods: {
      async getStation(){
        const q = query(collection(db, "stations"), where('wims_id', '==', this.wims_id), limit(1));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          this.station_info = doc.data();
        });
        if(this.station_info.name != null){
          this.validStation = true
          document.title = `${this.station_info.name} (${this.wims_id}) Details`;
          this.getPredictions();
        } else {
          this.doneLoading = true;
        }
      },
      async getPredictions(){
        const q6 = query(collection(db, "predictions"), where('wims_id', '==', this.wims_id), where('len', '==', 6), orderBy('generated','desc'), limit(1));
        const q12 = query(collection(db, "predictions"), where('wims_id', '==', this.wims_id), where('len', '==', 12), orderBy('generated', 'desc'), limit(1));
        const q24 = query(collection(db, "predictions"), where('wims_id', '==', this.wims_id), where('len', '==', 24), orderBy('generated', 'desc'), limit(1));
        const querySnapshot24 = await getDocs(q24);
        const querySnapshot12 = await getDocs(q12);
        const querySnapshot6 = await getDocs(q6);
        let predictions = Array()

        querySnapshot24.forEach((doc) => {
          predictions.push(doc.data());
        });

        querySnapshot12.forEach((doc) => {
          predictions.push(doc.data());
        });

        querySnapshot6.forEach((doc) => {
          predictions.push(doc.data());
        });
        
        this.predictions = predictions;
        if(this.predictions.length > 0){
          this.predicted_start = this.predictions[0]['time'][0];
          this.getActual()
          this.hasPredictions = true
          if(this.predicted_start.seconds + (60*60*2) < now.seconds){
            this.predictionLag = true;
          }
        } else {
          // do something if no data is pulled
          this.doneLoading = true;
        }
      },
      async getActual(){
        const q = query(collection(db, "data"), where('wims_id', '==', this.wims_id), orderBy('time', 'desc'), limit(6));
        const querySnapshot = await getDocs(q);
        let actual = Array()
        querySnapshot.forEach((doc) => {
          actual.unshift(doc.data())
        });
        this.actual = actual;
        this.buildGraphs = true;
        this.doneLoading = true;

        if(actual[actual.length-1].time.seconds + (60*60*2) < now.seconds){
          this.actualLag = true;
        }
      }
    },
    mounted() {
      this.getStation();
    },
    computed: {
        wims_id() {
          return parseInt(this.$route.params.wims_id);
        },
    }
  }
  </script>
  
  <style>
  .main-container {
    max-width: 1000px;
    margin: auto;
  }
  </style>
  
<template>
    <MainHeader />
        <div class="w-100 text-center" v-show="!doneLoading">
            <div class="spinner-border text-center" role="status">
                <span class="visually-hidden text-center">Loading...</span>
            </div>
        </div>
        <div v-if="doneLoading">
            <div class="main-container" v-if="!validStation">
                <p class="text-center">The provided WIMS ID is invalid.</p>
            </div>
            <div class="main-container" v-if="validStation && !hasPredictions">
                <p class="text-center">This station has not been configured to generate predictions.</p>
            </div>
        </div>
        <div class="main-container" v-if="validStation && hasPredictions">
            <div v-for="item in graph" v-bind:key="item">
                <GraphPopup :traces="item['traces']" :layout-trace="item['layoutTrace']" @close-window="graph = []" />
            </div>
            <h1 class="h3 mb-4">Model Assessment: {{ stationInfo['name'] }} ({{ wims_id }})</h1>
            <h2 class="h4">Full Run Assessment</h2>
            <p>This section of the model assessment will take full runs of predictions and calculate the mean absolute error (MAE) against actual measured data for the same timestamps.</p>
            <div v-for="maes, unit in fullRunMAEList" v-bind:key="unit">
                <h3 class="h5 my-4">{{ this.unit_strings[unit] }}</h3>
                <div class="accordion" :id="'accordion-full-'+unit">
                    <div v-for="list, timeframe in maes" v-bind:key="timeframe" class="accordion-item">
                        <h4 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-full-'+unit+'-'+timeframe" aria-expanded="false" :aria-controls="'collapse-full-'+unit+'-'+timeframe">
                                {{ timeframe }}HR Model: Average MAE (Last 5 Full Runs)
                            </button>
                        </h4>
                        <div :id="'collapse-full-'+unit+'-'+timeframe" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <span v-for="meanavgerr, index in list" v-bind:key="index" class="mx-2 d-block mb-2">
                                    {{ parseSeconds(graphDataFullRun[unit][timeframe]['time'][index][0].seconds) }}: {{ meanavgerr }} MAE ({{ units[unit] }})
                                    <button class="btn btn-secondary btn-sm my-2" v-on:click="showFullRunGraph(unit, timeframe, index)">View Plot</button>
                                </span>
                                <span v-if="!fullmeasure[timeframe.toString()] && list.length == 0">This model has not completed any full runs yet.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <h2 class="h4">Single Step Assessment</h2>
            <p>This section of the model assessment will take <em>n</em>th step prediction from each of the last full-run models and calculate the mean absolute error (MAE) against measured data.</p>
            <div v-for="maes, unit in mae_list" v-bind:key="unit">
                <h3 class="h5 my-4">{{ this.unit_strings[unit] }}</h3>
                <div class="accordion" :id="'accordion-'+unit">
                    <div v-for="list, timeframe in maes" v-bind:key="timeframe" class="accordion-item">
                        <h4 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+unit+'-'+timeframe" aria-expanded="false" :aria-controls="'collapse-'+unit+'-'+timeframe">
                                {{ timeframe }}HR Model: Average MAE (Each Step)
                            </button>
                        </h4>
                        <div :id="'collapse-'+unit+'-'+timeframe" class="accordion-collapse collapse">
                            <div class="accordion-body" v-if="measure[timeframe.toString()] && !stepErrors[timeframe.toString()]">
                                <span v-for="meanavgerr, index in list" class="mx-2 d-block mb-2" v-bind:key="meanavgerr">
                                    Step {{ index + 1 }} MAE ({{ units[unit] }}): {{ meanavgerr }}
                                    <button class="btn btn-secondary btn-sm mx-2" v-on:click="showSingleStepGraph(unit, timeframe, index)">View Plot</button>
                                </span>
                            </div>
                            <div class="accordion-body" v-if="!measure[timeframe.toString()]">
                                <p class="mb-0">Not enough runs to calculate each step.</p>
                            </div>
                            <div class="accordion-body" v-if="stepErrors[timeframe.toString()]">
                                <p class="mb-0">Not enough recent runs to calculate each step, likely due to gaps in data.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <MainFooter />
</template>

<script>
import db from '@/firebase/init.js';
import { query, collection, where, limit, getDocs, orderBy } from 'firebase/firestore';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
import GraphPopup from '@/components/GraphPopup.vue';

const { DateTime } = require('luxon');

export default {
    name: 'ModelAssessment',
    components: {
        MainHeader,
        MainFooter,
        GraphPopup
    },
    data(){
        return {
            stationInfo: {},
            validStation: false,
            hasPredictions: false,
            doneLoading: false,
            actual: [],
            predictions: {
                '6': [],
                '12': [],
                '24': []
            },
            mae_list: {
                'Temperature': {
                    '6': [],
                    '12': [],
                    '24': [],
                },
                'RelativeHumidity': {
                    '6': [],
                    '12': [],
                    '24': [],
                },
                'WindSpeed': {
                    '6': [],
                    '12': [],
                    '24': [],
                }
            },
            fullRunMAEList: {
                'Temperature': {
                    '6': [],
                    '12': [],
                    '24': [],
                },
                'RelativeHumidity': {
                    '6': [],
                    '12': [],
                    '24': [],
                },
                'WindSpeed': {
                    '6': [],
                    '12': [],
                    '24': [],
                }
            },
            measure: {
                '6': false,
                '12': false,
                '24': false
            },
            fullmeasure: {
                '6': false,
                '12': false,
                '24': false
            },
            graphDataSingleStep: {
                'Temperature': {
                    '6': {},
                    '12': {},
                    '24': {},
                },
                'RelativeHumidity': {
                    '6': {},
                    '12': {},
                    '24': {},
                },
                'WindSpeed': {
                    '6': {},
                    '12': {},
                    '24': {},
                }
            },
            graphDataFullRun:{
                'Temperature': {
                    '6': {},
                    '12': {},
                    '24': {},
                },
                'RelativeHumidity': {
                    '6': {},
                    '12': {},
                    '24': {},
                },
                'WindSpeed': {
                    '6': {},
                    '12': {},
                    '24': {},
                }                
            },
            units: {
                'Temperature': 'F',
                'RelativeHumidity': '%',
                'WindSpeed': 'mph'
            },
            unit_strings: {
                'Temperature': 'Temperature',
                'RelativeHumidity': 'Relative Humidity',
                'WindSpeed': 'Wind Speed'
            },
            graph: [],
            showGraphDiv: false,
            stepErrors: {
                '6': false,
                '12': false,
                '24': false
            }
        }
    },
    methods: {
        parseSeconds(seconds){
            seconds = seconds - (60*60)
            var dt = DateTime.fromSeconds(seconds);
            return dt.toFormat('D T ZZZZ');
        },
        showFullRunGraph(unit, timeframe, index){
            this.showGraphDiv = false;
            this.graph = [];
            this.graph[0] = {}
            this.graph[0]['layoutTrace'] = {
                title: this.unit_strings[unit] + ' (Predicted vs. Actual) (MAE: ' + this.fullRunMAEList[unit][timeframe][index] +')',
                xaxis: {
                    title: 'Date/Time (America/Phoenix)',
                    type: 'date',
                },
                yaxis: {
                    title: this.unit_strings[unit] + ' (' + this.units[unit] + ')'
                }
            };
            this.graph[0]['traces'] = [];
            
            let x = [];
            let pred_x = [];
            for(let i = 0; i < this.graphDataFullRun[unit][timeframe.toString()]['time'][index].length; i++){
                x.push(this.convertDate(this.graphDataFullRun[unit][timeframe.toString()]['time'][index][i], 'America/Phoenix'));
                pred_x.push(this.convertDate(this.graphDataFullRun[unit][timeframe.toString()]['pred_time'][index][i], 'America/Phoenix'));
            }

            this.graph[0]['traces'].push({
                x: pred_x,
                y: this.graphDataFullRun[unit][timeframe.toString()]['predicted'][index],
                type: 'scatter',
                name: 'Predicted',
                marker: {
                    size: 1
                },
                line: {
                    color: 'blue'
                }
            });
            this.graph[0]['traces'].push({
                x: x,
                y: this.graphDataFullRun[unit][timeframe.toString()]['actual'][index],
                type: 'scatter',
                name: 'Actual',
                marker: {
                    size: 1
                },
                line: {
                    color: 'black'
                }
            });
            this.showGraphDiv = true;
        },
        showSingleStepGraph(unit, timeframe, index){
            this.showGraphDiv = false;
            this.graph = [];
            this.graph[0] = {}
            this.graph[0]['layoutTrace'] = {
                title: this.unit_strings[unit] + ' (Step ' + (index+1) +' Predictions) (MAE: ' + this.mae_list[unit][timeframe][index] +')',
                xaxis: {
                    title: 'Date/Time (America/Phoenix)',
                    type: 'date',
                },
                yaxis: {
                    title: this.unit_strings[unit] + ' (' + this.units[unit] + ')'
                }
            };
            this.graph[0]['traces'] = [];
            
            let x = [];
            let pred_x = [];
            for(let i = 0; i < this.graphDataSingleStep[unit][timeframe.toString()]['time'][index].length; i ++){
                x.push(this.convertDate(this.graphDataSingleStep[unit][timeframe.toString()]['time'][index][i], 'America/Phoenix'));
                pred_x.push(this.convertDate(this.graphDataSingleStep[unit][timeframe.toString()]['pred_time'][index][i], 'America/Phoenix'));
            }

            this.graph[0]['traces'].push({
                x: pred_x,
                y: this.graphDataSingleStep[unit][timeframe.toString()]['steps'][index],
                type: 'scatter',
                name: 'Step '+ (index+1) +' Predictions',
                marker: {
                    size: 1
                },
                line: {
                    color: 'blue'
                }
            });
            this.graph[0]['traces'].push({
                x: x,
                y: this.graphDataSingleStep[unit][timeframe.toString()]['actual'][index],
                type: 'scatter',
                name: 'Actual',
                marker: {
                    size: 1
                },
                line: {
                    color: 'black'
                }
            });
            this.showGraphDiv = true;
        },
        async getStation(){
            const q = query(collection(db, "stations"), where('wims_id', '==', this.wims_id), limit(1));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                this.stationInfo = doc.data();
            });
            if(this.stationInfo['name'] != undefined){
                this.validStation = true;
                document.title = 'Model Assessment: ' + this.stationInfo['name'] + ' ('+ this.wims_id +')'
                this.getPredictions();
            } else {
                this.doneLoading = true;
            }
        },
        async getPredictions(){
            const q6 = query(collection(db, "predictions"), where("wims_id", "==", this.wims_id), where("len", "==", 6), orderBy("generated", "desc"), limit(12));
            const querySnapshot6 = await getDocs(q6);
            const q12 = query(collection(db, "predictions"), where("wims_id", "==", this.wims_id), where("len", "==", 12), orderBy("generated", "desc"), limit(24));
            const querySnapshot12 = await getDocs(q12);
            const q24 = query(collection(db, "predictions"), where("wims_id", "==", this.wims_id), where("len", "==", 24), orderBy("generated", "desc"), limit(48));
            const querySnapshot24 = await getDocs(q24);

            querySnapshot6.forEach((doc) => {
                this.predictions['6'].unshift(doc.data());
            });

            querySnapshot12.forEach((doc) => {
                this.predictions['12'].unshift(doc.data());
            });

            querySnapshot24.forEach((doc) => {
                this.predictions['24'].unshift(doc.data());
            });

            if(this.predictions['24'].length > 0 || this.predictions['12'].length > 0 || this.predictions['6'].length > 0){
                this.hasPredictions = true;
            } else {
                this.doneLoading = true;
            }

            if(this.predictions['24'].length == 48){
                this.measure['24'] = true;
            }
            if(this.predictions['12'].length == 24){
                this.measure['12'] = true;
            }
            if(this.predictions['6'].length == 12){
                this.measure['6'] = true;
            }

            if(this.predictions['24'].length > 24){
                this.fullmeasure['24'] = true;
            }
            if(this.predictions['12'].length > 12){
                this.fullmeasure['12'] = true;
            }
            if(this.predictions['6'].length > 6){
                this.fullmeasure['6'] = true;
            }

            this.getActual();
        },
        async getActual(){
            const q = query(collection(db, "data"), where('wims_id', '==', this.wims_id), orderBy('time', 'desc'), limit(47));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                this.actual.unshift(doc.data());
            })
            for(let i = 0; i < Object.keys(this.measure).length; i++){
                if(this.measure[Object.keys(this.measure)[i]]){
                    this.generateGraphDataSingleStepTest(parseInt(Object.keys(this.measure)[i]));
                }
            }
            this.generateFullRunGraphData();
            this.doneLoading = true;
        },
        generateGraphDataSingleStep(timeframe){
            for(let i = 0; i < Object.keys(this.graphDataSingleStep).length; i++){
                var variable = Object.keys(this.graphDataSingleStep)[i];
                var steps = []
                var actual = []
                var timestamps = []
                var pred_timestamps = []
                var start_index = (this.actual.length) - ((timeframe*2)-1);
                for(let j = 0; j < timeframe; j++){
                    steps[j] = []
                    actual[j] = []
                    timestamps[j] = []
                    pred_timestamps[j] = []
                    for(var k = 0; k < timeframe; k++){
                        steps[j].push(this.predictions[timeframe.toString()][k][variable][j]);
                        actual[j].push(this.actual[start_index+j+k][variable]);
                        timestamps[j].push(this.actual[start_index+j+k]['time'])
                        pred_timestamps[j].push(this.predictions[timeframe.toString()][k]['time'][j])
                    }
                }
                this.graphDataSingleStep[variable][timeframe.toString()] = {
                    'time': timestamps,
                    'actual': actual,
                    'steps': steps,
                    'pred_time': pred_timestamps
                }
                for(let j = 0; j < steps.length; j++){
                    this.mae_list[variable][timeframe.toString()].push(this.meanAverageError(steps[j], actual[j]).toFixed(3))
                }
            }
        },
        generateGraphDataSingleStepTest(timeframe){
            let step_list = []
            for(let i = 0; i < timeframe; i++){
                //for each step in timeframe
                step_list.push(this.predictions[timeframe.toString()][i]['time'][0])
            }
            if(this.isSequential(step_list) && this.timestepsForEach(step_list)){
                // is sequential, meaning we can calulate single step shit
                this.generateGraphDataSingleStep(timeframe);
            } else {
                // not sequential, meaning we have a gap in prediction times
                this.stepErrors[timeframe] = true;
            }
        },
        isSequential(time_list){
            var sequential = true;
            for(let i = 0; i < time_list.length; i++){
                if((i+1 == time_list.length) && sequential){
                    // this is the last value, and we've been sequential so far so it's already been checked
                    continue;
                }
                if(time_list[i].seconds + (60*60) != time_list[i+1].seconds){
                    sequential = false;
                    break;
                }
            }
            return sequential;
        },
        timestepsForEach(step_list){
            var enoughData = false;
            if(step_list[0].seconds >= this.actual[0]['time'].seconds){
                enoughData = true;
            }
            return enoughData;
        },
        generateFullRunGraphData(){
            let variables = Object.keys(this.fullRunMAEList);
            let timeframes = Object.keys(this.predictions);
            for(let i = 0; i < timeframes.length; i++){
                //for each prediction timeframe
                let timestamps = {
                    'Temperature': [],
                    'RelativeHumidity': [],
                    'WindSpeed': []
                };
                let actual_values = {
                    'Temperature': [],
                    'RelativeHumidity': [],
                    'WindSpeed': []
                };
                let predicted_values = {
                    'Temperature': [],
                    'RelativeHumidity': [],
                    'WindSpeed': []
                };
                let predicted_timestamps = {
                    'Temperature': [],
                    'RelativeHumidity': [],
                    'WindSpeed': []
                };
                for(let j = 0; j < this.predictions[timeframes[i]].length; j++){
                    //for each prediction set in timeframe
                    if(this.predictions[timeframes[i]][j]['time'][this.predictions[timeframes[i]][j]['time'].length-1] <= this.actual[this.actual.length-1]['time']){
                        // this prediction has all available timestamps within actual data pulled, we can calculate mae
                        let actual_temp = this.getActualObject(this.predictions[timeframes[i]][j]);
                        if(actual_temp != null){
                            for(let k = 0; k < variables.length; k++){
                                //for each variable in valid prediction
                                this.fullRunMAEList[variables[k]][timeframes[i]].unshift(this.meanAverageError(actual_temp[variables[k]], this.predictions[timeframes[i]][j][variables[k]]).toFixed(3));
                                timestamps[variables[k]].unshift(actual_temp['time']);
                                actual_values[variables[k]].unshift(actual_temp[variables[k]]);
                                predicted_values[variables[k]].unshift(this.predictions[timeframes[i]][j][variables[k]]);
                                predicted_timestamps[variables[k]].unshift(this.predictions[timeframes[i]][j]['time']);

                                // slice them to just get the last 5 full runs
                                this.fullRunMAEList[variables[k]][timeframes[i]] = this.fullRunMAEList[variables[k]][timeframes[i]].slice(0, 5);
                                timestamps[variables[k]] = timestamps[variables[k]].slice(0, 5);
                                actual_values[variables[k]] = actual_values[variables[k]].slice(0, 5);
                                predicted_values[variables[k]] = predicted_values[variables[k]].slice(0, 5);
                                predicted_timestamps[variables[k]] = predicted_timestamps[variables[k]].slice(0, 5);
                            }
                        }
                    }
                }
                for(let k = 0; k < variables.length; k++){
                    this.graphDataFullRun[variables[k]][timeframes[i]] = {
                        'time': timestamps[variables[k]],
                        'actual': actual_values[variables[k]],
                        'predicted': predicted_values[variables[k]],
                        'pred_time': predicted_timestamps[variables[k]]
                    }
                }
            }
        },
        getActualObject(predictionSet){
            let index = null;
            for(let i = 0; i < this.actual.length; i++){
                if(this.actual[i]['time'].isEqual(predictionSet['time'][0])){
                    index = i;
                    break;
                }
            }
            if(index == null){
                return null;
            }
            let return_obj = {
                'Temperature': [],
                'RelativeHumidity': [],
                'WindSpeed': [],
                'time': []
            };
            let variables = ['Temperature', 'RelativeHumidity', 'WindSpeed', 'time'];
            for(let i = index; i < index + predictionSet['time'].length; i++){
                for(let j = 0; j < variables.length; j++){
                    return_obj[variables[j]].push(this.actual[i][variables[j]]);
                }
            }
            return return_obj;
        },
        meanAverageError(actual, predicted){
            let sum = 0;
            for(let i = 0; i < actual.length; i++){
                sum += Math.abs(predicted[i] - actual[i]);
            }
            return sum / actual.length;
        },
        convertDate(date, tz){
            var dt = DateTime.fromJSDate(date.toDate()).setZone(tz)
            return dt.toFormat('y-LL-dd HH:mm:ss')
        },
    },
    mounted(){
        document.title = 'Model Assessment';
        this.getStation();
    },
    computed: {
        wims_id(){
            return parseInt(this.$route.params.wims_id);
        }
    }
}
</script>

<style scoped>
  .main-container {
    max-width: 1000px;
    margin: auto;
    box-sizing: border-box;
    padding: 1em;
  }
</style>
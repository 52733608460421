<template>
    <div :id="name"></div>
</template>
  
<script>

import Plotly from 'plotly.js-dist';

export default {
    name: 'DataGraph',
    props: {
        name: String,
        refresh: Boolean,
        layoutTrace: Object,
        traces: Array
    },
    data(){
        return {

        }
    },
    methods: {
        generateGraph(){
            Plotly.react(this.name, this.traces, this.layoutTrace, {responsive: true});
        },
    },
    mounted() {
        this.generateGraph();
    },
    watch: {
        refresh: function(newVal){
            if(newVal == true){
                this.generateGraph();
            }
        },
    }
}
</script>

<style scoped>
div {
    max-width: 100%; 
    min-height: 400px;
}
</style>
  
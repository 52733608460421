<template>
  <MainHeader/>
  <div class="w-100 text-center" v-show="!doneLoading">
    <div class="spinner-border text-center" role="status">
      <span class="visually-hidden text-center">Loading...</span>
    </div>
  </div>
  <div v-if="doneLoading && stationlist.length == 0">
    <div class="main-container">
      <p class="text-center">No stations have been configured for use with this application.</p>
    <MainFooter/>
    </div>
  </div>
  <div class="main-container" :style="(doneLoading && hasStations) ? 'visibility: visible;' : 'visibility: hidden;'">
    <h1 class="text-center">Station Map</h1>
    <div id="mapContainer"></div>
    <div id="stationDetails" class="container mt-4" v-show="details.show">
      <div class="row">
        <div class="col-12 col-md-6">
          <h2 class="h4 mt-2">{{ details.name }} ({{ details.wims_id }})</h2>
          <p><strong>Location:</strong> {{ details.location.lat }}, {{ details.location.lng }}</p>
          <p><a class="btn btn-primary btn-sm" :href="'/station/' + details.wims_id">Station Details</a></p>
        </div>
        <div class="col-12 col-md-6">
          <h3 class="my-2 h5">Wind Speed / Relative Humidity</h3>
          <div v-if="flags[details.wims_id] != undefined && !actualLag">
            <h4 class="my-2 h6">Measured:</h4>
            <p class="my-2 small">
              Last 24 Hours: <span :class="flag != 'No flags' ? 'badge text-bg-warning mx-1' : 'badge text-bg-success mx-1'" v-for="flag in flags[details.wims_id][0]" v-bind:key="flag">{{ flag }}</span>
            </p>
          </div>
          <div v-if="actualLag">
            <div class="alert alert-danger" role="alert">
              Missing latest data.
            </div>
          </div>
          <div v-if="flags[details.wims_id] != undefined && Object.keys(flags[details.wims_id][1]).length > 0 && !predictionLag">
            <h4 class="my-2 h6">Predicted:</h4>
            <p class="my-2 small" v-for="flag, timeframe in flags[details.wims_id][1]" v-bind:key="flag">{{ timeframe }}HR Model: <span :class="f != 'No flags' ? 'badge text-bg-warning mx-1' : 'badge text-bg-success mx-1'" v-for="f in flag" v-bind:key="f">{{ f }}</span></p>
          </div>
          <div v-if="predictionLag">
            <div class="alert alert-danger" role="alert">
              Missing latest predictions.
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter/>
  </div>
</template>
  
<script>
import db from '@/firebase/init.js'
import { query, collection, getDocs, where, orderBy, limit, Timestamp } from "firebase/firestore";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MainFooter from "@/components/MainFooter.vue";
import MainHeader from "@/components/MainHeader.vue";

const now = Timestamp.now();
const map = [];

export default {
  name: 'HomeView',
  components: {
    MainFooter,
    MainHeader
  },
  data() {
    return {
      doneLoading: false,
      stationlist: Array(),
      details: {
        name: null,
        wims_id: null,
        show: false,
        location: {
          lat: null,
          lng: null
        },
        list_index: 0
      },
      flags: {},
      actual: {},
      predictionLag: false,
      actualLag: false,
    }
  }, 
  methods: {
    async showDetails(marker){
      let info = marker.target.options;
      this.details.name = info.name;
      this.details.wims_id = info.wims_id;
      this.details.location = marker.latlng;
      this.details.show = true
    },
    mapSetup(){
      const mapDiv = L.map("mapContainer", {minZoom: 5}).setView([32.311111, -110.818611], 8);
      L.tileLayer(
        "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
            {
              attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
              maxZoom: 18,
            } 
        ).addTo(mapDiv);
        map.push(mapDiv)
    },
    async getStations(){
      const self = this;
      const q = query(collection(db, "stations"));
      const querySnapshot = await getDocs(q);
      let stations = Array()
      querySnapshot.forEach((doc) => {
        stations.push(doc.data())
      });
      this.stationlist = stations;
      var markers = [];
      if(this.stationlist.length > 0){
        this.mapSetup();
        for(let i = 0; i < this.stationlist.length; i++){          
          let station = this.stationlist[i];
          var last24Flags = await this.getActualFlags(station['wims_id']);
          var predictedFlags = await this.getPredictedFlags(station['wims_id']);
          this.flags[station['wims_id'].toString()] = [last24Flags, predictedFlags];
          var newLayer = L.marker([station['location'].latitude, station['location'].longitude],
            {
              wims_id: station['wims_id'],
              name: station['name'],
              icon: new L.DivIcon({
                html: '<img src="raws_station.svg" width=64 height=64>'+
                this.generateBadge(this.flags[station['wims_id'].toString()], station['name']),
                iconSize: [80, 64],
                className: 'div-icon'
              })
            })
          newLayer.bindPopup(`<p class="mb-2">${station.name} (${station.wims_id})</p><p class="mt-0 text-center"><a class='btn btn-primary btn-sm text-white' href='/station/${station.wims_id}'>Station Details</a></p>`).addTo(map[0]);
          newLayer.on('click',function(e){
              self.showDetails(e);
            })
          markers.push(newLayer);
        }
        map[0].fitBounds(L.featureGroup(markers).getBounds());
        this.doneLoading = true;
        this.hasStations = true;
      } else {
        this.doneLoading = true;
      }
    },
    generateBadge(badges, name){
      let badgeClass = 'text-bg-success';
      try{
        if(badges[0].length > 1){
          //past danger flag
          badgeClass = 'text-bg-danger';
        } else if(badges[0][0] == 'No data') {
          badgeClass = 'text-bg-secondary';
        } else if(badges[0][0] != 'No flags'){
          // past warning flag
          badgeClass = 'text-bg-warning';
        }

        if(badgeClass == 'text-bg-success'){
          let badgeTimes = [6, 12, 24];
          for(let i = 0; i < badgeTimes.length; i++){
            if(badges[1][badgeTimes[i]].length > 1){
              badgeClass = 'text-bg-danger';
              break;
            } else if(badges[1][badgeTimes[i]][0] != 'No flags'){
              badgeClass = 'text-bg-warning';
            }
          }
        }

        return `<span class="badge ${badgeClass} text-uppercase">${name}</span>`;
      } catch {
        return `<span class="badge text-bg-secondary text-uppercase">${name}</span>`;
      }
    },
    async getPredictedFlags(wims_id){
      let flags = {};
      let lengths = [6, 12, 24];

      for(let i = 0; i < lengths.length; i++){
        const q = query(collection(db, 'predictions'), where('wims_id', '==', wims_id), where('len', '==', lengths[i]), orderBy('generated', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);
        let data = []
        querySnapshot.forEach((doc) => {
          data = doc.data();
        });

        if(data.length == 0){
          continue;
        } else {
          if(data.time[0].seconds + (60*60*2) < now.seconds){
            this.predictionLag = true;
          }          
        }

        let clean_data = []
        for(let j = 0; j < data['time'].length; j++){
          clean_data.push({
            'WindSpeed': data['WindSpeed'][j],
            'RelativeHumidity': data['RelativeHumidity'][j]
          });
        }
        flags[lengths[i].toString()] = [];

        if(this.checkVariable(clean_data, 'WindSpeed', 20, true)){
          flags[lengths[i].toString()].push('High winds');
        }
        if(this.checkVariable(clean_data, 'RelativeHumidity', 20, false)){
          flags[lengths[i].toString()].push('Low humidity');
        }
        if(flags[lengths[i].toString()].length == 0){
          flags[lengths[i].toString()] = ['No flags']
        }
      }
      return flags;
    },
    async getActualFlags(wims_id){
      let flags = []
      const q = query(collection(db, 'data'), where('wims_id', '==', wims_id), orderBy('time', 'desc'), limit(24));
      const querySnapshot = await getDocs(q);
      let actual = [];
      querySnapshot.forEach((doc) => {
          actual.unshift(doc.data())
      });

      if(actual.length == 0){
        return ['No data'];
      } else {
        if(actual[actual.length-1].time.seconds + (60*60*2) < now.seconds){
          this.actualLag = true;
        }
      }

      this.actual[wims_id.toString()] = actual

      if(this.checkVariable(actual, 'WindSpeed', 20, true)){
        flags.push('High winds');
      }
      if(this.checkVariable(actual, 'RelativeHumidity', 20, false)){
        flags.push('Low humidity');
      }
      if(flags.length == 0){
        flags = ['No flags']
      }
      return flags;
    },
    checkVariable(measurements, variable, threshold, above){
      let consecutive = 3;
      let within_12 = 6;
      let retVal = false;
      let consecutive_count = 0
      let last_12 = Array();
      for(let i = 0; i < measurements.length; i++){
        last_12.push(measurements[i][variable])
        if(last_12.length > 12){
          last_12.shift();
        }
        if(above){
          if(measurements[i][variable] >= threshold){
            consecutive_count++;
          } else {
            consecutive_count = 0;
          }
        } else {
          if(measurements[i][variable] <= threshold){
            consecutive_count++;
          } else {
            consecutive_count = 0;
          }
        }
        if(consecutive_count == consecutive){
          retVal = true;
          break;
        }
        let counter = 0;
        for(let j = 0; j < last_12.length; j++){
          if(above){
            if(last_12[j] >= threshold){
              counter++;
            }
          } else {
            if(last_12[j] <= threshold){
              counter++;
            }                
          }
          if(counter >= within_12){
            retVal = true;
            break;
          }
        }
        if(retVal == true){
          break;
        }            
      }
    
      return retVal;
    },
  },
  mounted(){
    this.getStations();
    document.title = `RAWS Machine Learning`;
  },
}
</script>

<style>
#mapContainer{ 
  max-width: 100%;
  height: 600px;
  margin: auto;
}
.main-container {
  max-width: 1000px;
  margin: auto;
}
.div-icon {
  text-align: center;
  width: 64px;

}
.div-icon img {
  width: 100%;
  margin: auto;
}

.div-icon span {
  width: 100%;
}
</style>
  
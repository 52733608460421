import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

let config = {
    apiKey: "AIzaSyASaSxblMdIzsGgJsgg52kTZ-mor2ZE7f0",
    authDomain: "fireweather-ml.firebaseapp.com",
    projectId: "fireweather-ml",
    storageBucket: "fireweather-ml.appspot.com",
    messagingSenderId: "278769430367",
    appId: "1:278769430367:web:d150cf81fb8c1ad62890ea"
};

const app = initializeApp(config);

const db = getFirestore(app);

export default db;